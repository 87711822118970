import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "ccFeeList",

  data() {
    return {
      ccFeeList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      selectedRows: [],
      sortColumn: "type",
      sortDirection: "asc",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.ccFeeList.length > 0) {
        return (
          _.where(this.ccFeeList, { isSelected: true }).length ===
          this.ccFeeList.length
        );
      }
      return false;
    },
    hasCCFEEShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCCFEEAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCCFEEDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCCFEEUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    checkUncheckedList(event) {
      // Iterate through each element in ccFeeList and update the isSelected property based on the event's target checked value.
      this.ccFeeList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },

    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a single id is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Loop through the ccFeeList to find selected elements
        this.ccFeeList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Send a POST request to delete the records
      _vm.axios
        .post("/ccs-deletes", { ids: ids })
        .then(function () {
          // Refresh the data after successful deletion
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {
          // Handle errors during deletion
        });
    },

    search(value) {
      this.getAll();
    },
    getQueryString() {
      // Construct the query string
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      // Add the search parameter if searchText is not empty
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      // Return the constructed query string
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      // Check if the clicked column is already the sorting column
      if (this.sortColumn === columnName) {
        // If it is, reverse the sort direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        // If it's a different column, set it as the new sorting column
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      // Call the getAll function to update the sorted data
      this.getAll();
    },

    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Make a GET request to retrieve data from the "/ccs" endpoint
      this.axios
        .get("/ccs" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          // Update the ccFeeList with the received data
          _vm.ccFeeList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          // Calculate the page count based on total records and record take value
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {
          // Handle any errors that occurred during the GET request
        });
    },

    handleCheck(e) {
      // Check if the event target ID is "customCheck-1"
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // If the checkbox is checked, select all rows and update isSelected property
          this.selectedRows = this.ccFeeList.map(
            (item) => "checkbox" + item.id
          );
          this.ccFeeList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // If the checkbox is unchecked, deselect all rows and update isSelected property
          this.selectedRows = [];
          this.ccFeeList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // If a checkbox other than "customCheck-1" is checked, add it to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // If a checkbox other than "customCheck-1" is unchecked, remove it from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
      // End of handleCheck function
    },
  },
  mounted() {
    this.getAll();
  },
};
